
.bg-gradient-dark{
    background: #2A3F54;
    height: 100%;
}
footer.sticky-footer{
    padding: 1rem 0 0.6rem 0;
}
.sidebar-toggled .toggled .ad-logo img{
    max-width: 60px;
}
@media (max-width: 767px) {
    .ad-logo img{
        width: 65px;
    }
}
.ad-logo img{
    max-width: 95px;
}

/*CSS Add Product*/
.form-control.has-feedback-left {
    padding-left: 45px;
}
.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
}
.form-control-feedback {
    margin-top: 8px;
    height: 23px;    
    line-height: 24px;
    font-size: 15px;
}
.form-control-feedback.left {
    border-right: 1px solid #ccc;
    left: 13px;
}
.col-md-12.text-center.btn-action-addProduct{
    padding: 5px;
}
.col-md-12.text-center.btn-action-addProduct button{
    margin: 5px;
}
#idActionAddProduct .row button, #idThaoTac2 .row button{
    margin: 0 5px;
    height: 35px;
}
#idActionAddProduct .btn-circle{
    height: 35px;
    width: 35px;
    font-size: 0.8rem;
}
.child-cate-product{
    margin-left: 15px;
}
/*Style Dropzone*/
.wrap-dropzone {
    padding: 16px;
    border: 1px #e8e8e8 solid;
    border-radius: 3px;
    width: 100%;
    display: inline-block;
    margin-bottom: 30px;
  }
.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    line-height: 60px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}
.dropzone p {
    margin-bottom: 0;
}
.img-ViewDetail{
    cursor: pointer;
}
div#mediaManagerModal .wrapper-full #wrapper #content-wrapper #content .container-fluid {
    padding: 15px;
}
div.btn{
    cursor: pointer;
    margin: 0 5px;
}
.modal {
    overflow: auto !important;
}
.modal-overflow{
    overflow: hidden !important;
}
#idGalleryImageAddProduct img, #idImageAddProduct img{
    cursor: pointer;
}
.getListMedia .col-2{
    margin-bottom: 15px;
}
.table-bordered th, .table-bordered td{
    vertical-align:middle;
}
.table-bordered td .btn{
    margin: 0 5px 0 0;
}
#idChonLoaiSP .custom-radio input, #idChonDanhMucSP .custom-checkbox input, #idChonWeb .custom-checkbox input,
#idChonLoaiSP .custom-radio label, #idChonDanhMucSP .custom-checkbox label, #idChonWeb .custom-checkbox label{
    cursor: pointer;
}