:root {
    --input-padding-x: 1.5rem;
    --input-padding-y: .75rem;
}
body.isLogin {
    background: url(../images/Bg_vhpgroup.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    overflow: auto;
}

.logoVHPGinseng-login-a{
    width: 300px;
}
.img-fluid.logo-Slogan{
    margin-top: 30px;
    width: 220px !important;  
}
body.isLogin input:focus{
    border-color: red;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}
body.isLogin .card {
    background-color: rgba(0, 0, 0, 0.3) !important;
}
body.isLogin .card-signin {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1) !important;
}

body.isLogin .card-signin .card-title {
    margin-bottom: 1rem;
    font-weight: 300;
    font-size: 1.5rem;
}

body.isLogin .card-signin .card-body {
    padding: 2rem;
}
/* body.isLogin .form-control {    
    background-color: rgba(0, 0, 0, 0.8);
    color: #01458e;
}
body.isLogin .form-control:focus {
    background-color: rgba(255, 255, 255, 0.5);
    color: #01458e;
}
body.isLogin .form-label-group label {
    color:#376737;
 } */

.form-signin {
    width: 100%;
}

.form-signin .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.form-label-group input {
    height: auto;
    border-radius: 2rem;
}

.form-label-group>input,
.form-label-group>label {
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-moz-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
}

.btn-google {
    color: white;
    background-color: #ea4335;
}

.btn-facebook {
    color: white;
    background-color: #3b5998;
}